import React from 'react';
import burjArab from '../assets/kkraimg/burj-al-arab.jpg';
import atlantis from '../assets/kkraimg/atlantis.jpg';
import marriott from '../assets/kkraimg/w-marriott.jpg';
import armani from '../assets/kkraimg/armani-hotel.jpg';
import royal from '../assets/kkraimg/le-royal.jpg';

const deals = [
  {
    id: 1,
    title: "Burj Al Arab Jumeirah",
    location: "DUBAI",
    price: "499,00",
    image: burjArab
  },
  {
    id: 2,
    title: "Atlantis The Palm",
    location: "DUBAI",
    price: "399,00",
    image: atlantis
  },
  {
    id: 3,
    title: "JW Marriott Marquis",
    location: "DUBAI",
    price: "349,00",
    image: marriott
  },
  {
    id: 4,
    title: "Armani Hotel Dubai",
    location: "DUBAI",
    price: "599,00",
    image: armani
  },
  {
    id: 5,
    title: "Le Royal Meridien Beach Resort & Spa",
    location: "DUBAI",
    price: "259,00",
    image: royal
  }
];

const DealCard = ({ title, location, price, image, colSpan }) => (
  <div className={`relative rounded-lg overflow-hidden md:col-span-${colSpan} col-span-10`}>
    <img
      src={image}
      alt={title}
      className="w-full object-cover h-[300px] md:h-[400px]" // Responsive height adjustment
    />
    <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
      <h3 className="text-white text-lg font-semibold">{title} - {location}</h3>
      <p className="text-white text-sm mt-1">
        €{price} <span className="text-xs">Price starts from</span>
      </p>
    </div>
  </div>
);

const TravelDeals = () => {
  const colSpans = [7, 3, 3, 4, 3]; // You can adjust this as per your design preference

  return (
    <div className="container md:w-[90%] w-full mx-auto py-8">
      <h1 className="text-3xl md:text-4xl font-bold mb-6 text-center md:text-left">Top Deals and Discounts</h1>
      <div className="grid grid-cols-10 gap-4">
        {deals.map((deal, index) => (
          <DealCard
            key={deal.id}
            {...deal}
            colSpan={colSpans[index]}
          />
        ))}
      </div>
    </div>
  );
};

export default TravelDeals;

