import React, { useState } from 'react';
import { Calendar } from 'lucide-react';

const TravelBookingForm = () => {
  const [formData, setFormData] = useState({
    departure: '',
    destination: '',
    departDate: '',
    returnDate: '',
    travelers: '',
    travelType: 'Business',
    name: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Here you would typically send the data to a server
  };

  return (
    <div className="flex justify-center items-center min-h-screen w-full px-4">
      <div className="w-full md:max-w-4xl bg-white rounded-xl overflow-hidden shadow-lg">
        <div className="p-8 md:p-12">
          
          <h3 className="text-2xl md:text-3xl font-semibold mb-6 text-center">
            Your Journey Begins Here
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
              <input
                type="text"
                name="departure"
                placeholder="Departure"
                value={formData.departure}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                name="destination"
                placeholder="Destination"
                value={formData.destination}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />

              <div className="relative">
                <input
                  type="date"
                  name="departDate"
                  placeholder="Depart date"
                  value={formData.departDate}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <Calendar className="absolute right-3 top-3 text-gray-400" size={20} />
              </div>

              <div className="relative">
                <input
                  type="date"
                  name="returnDate"
                  placeholder="Return date"
                  value={formData.returnDate}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <Calendar className="absolute right-3 top-3 text-gray-400" size={20} />
              </div>

              <input
                type="number"
                name="travelers"
                placeholder="# Travelers"
                value={formData.travelers}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />

              <select
                name="travelType"
                value={formData.travelType}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="Business">Business</option>
                <option value="Leisure">Leisure</option>
                <option value="Family">Family</option>
              </select>

              <input
                type="text"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 md:col-span-2"
              />

              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 md:col-span-2"
              />
            </div>

            <button
              type="submit"
              className="mt-6 w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TravelBookingForm;
