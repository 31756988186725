import React from "react";
import { Check, X, Crown, Star, User } from "lucide-react"; // Lucide icons for the features
import { FaCrown, FaUser, FaStar } from "react-icons/fa"; // Font Awesome icons for the plan tiers
import BnbNav from "components/NavBar/BnbNav";
import Footerbn from "pages/Footer/Footerbn";
import { motion } from "framer-motion";

const PricingTier = ({ name, price, features, recommended, icon }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.9 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.3, ease: "easeOut" }}
    className={`bg-white rounded-3xl hover:bg-gray-200 shadow-xl overflow-hidden transform transition-all duration-300  ${
      recommended ? "" : ""
    }`}
  >
    {recommended && (
      <div className="bg-yellow-500 text-white text-center py-3 font-semibold rounded-t-lg">
        Most Popular
      </div>
    )}
    <div className="p-8 ">
      <div className="flex justify-center mb-4">
        {/* Plan Icon */}
        {icon}
      </div>
      <h3 className="text-2xl font-bold text-gray-800 mb-4">{name}</h3>
      <p className="text-4xl font-extrabold text-gray-800 mb-6">
        {price}{" "}
        <span className="text-lg font-normal text-gray-500">/month</span>
      </p>
      <ul className="space-y-4 mb-8">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            {feature.included ? (
              <Check className="h-6 w-6 text-green-500 mr-2" />
            ) : (
              <X className="h-6 w-6 text-red-500 mr-2" />
            )}
            <span
              className={
                feature.included ? "text-gray-700 font-medium" : "text-gray-400"
              }
            >
              {feature.text}
            </span>
          </li>
        ))}
      </ul>
      <button className="w-fit px-6 bg-gradient-to-r from-yellow-400 hover:scale-110  to-yellow-500 text-white rounded-lg py-3 font-semibold hover:bg-yellow-600 transition-all duration-300 shadow-md">
        Subscribe Now
      </button>
    </div>
  </motion.div>
);

const SubscriptionPage = () => {
  const tiers = [
    {
      name: "BASIC",
      price: "AED 99",
      icon: <FaUser className="text-blue-500 text-6xl" />, // Icon for Basic plan
      features: [
        { text: "Access to basic city tours", included: true },
        { text: "10% off on select experiences", included: true },
        { text: "Weekly newsletter", included: true },
        { text: "24/7 customer support", included: true },
        { text: "Priority booking", included: false },
        { text: "Exclusive event access", included: false },
      ],
      recommended: false,
    },
    {
      name: "PREMIUM",
      price: "AED 199",
      icon: <FaStar className="text-yellow-500 text-6xl" />, // Icon for Premium plan
      features: [
        { text: "All Basic features", included: true },
        { text: "Access to premium desert safaris", included: true },
        { text: "20% off on all experiences", included: true },
        { text: "Monthly exclusive Dubai activity", included: true },
        { text: "Priority booking", included: true },
        { text: "Luxury airport transfers", included: false },
      ],
      recommended: true,
    },
    {
      name: "VIP",
      price: "AED 399",
      icon: <FaCrown className="text-purple-600 text-6xl" />, // Icon for VIP plan
      features: [
        { text: "All Premium features", included: true },
        { text: "Unlimited access to all experiences", included: true },
        { text: "30% off on luxury accommodations", included: true },
        { text: "Quarterly exclusive fine dining event", included: true },
        { text: "Personal travel concierge", included: true },
        { text: "Luxury airport transfers", included: true },
      ],
      recommended: false,
    },
  ];

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      {/* <BnbNav /> */}
      <motion.section
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.5 }}
        className="text-center mb-16"
      >
        <div className="bg-gradient-to-br from-gray-100 to-gray-200  h-[100%] py-12 px-4 sm:px-6 lg:px-8 mt-16">
          <div className="max-w-6xl mx-auto">
            <div className="text-center mb-12">
              <h1 className="text-3xl font-extrabold text-gray-900 sm:text-3xl sm:tracking-tight lg:text-4xl">
                Unlock the Best Travel Deals with Our Subscription!
              </h1>
              <p className="mt-5 max-w-xl mx-auto text-xl text-gray-500">
                Choose a Plan and Gain Access to Exclusive Packages, Special
                Discounts, and Priority Support for Your Next Adventure.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
              {tiers.map((tier, index) => (
                <PricingTier key={index} {...tier} />
              ))}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <Footerbn /> */}
    </>
  );
};

export default SubscriptionPage;
