import React, { useState } from 'react';
import { Calendar, Plane, Users, Map } from 'lucide-react';
import { BaseUrl } from '../BaseUrl';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const FlightBookingForm = () => {
  const [tripType, setTripType] = useState('roundTrip');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [departDate, setDepartDate] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [passengers, setPassengers] = useState(1);
  const [children, setChildren] = useState(1);

  const location = useLocation();
  const { name, selectedType } = location.state || {};
  const cities = ['Dubai', 'New York', 'London', 'Tokyo', 'Paris', 'Sydney', 'Berlin', 'Rome'];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const bookingDate = new Date().toISOString().split('T')[0];

    const bookingData = {
      tripType,
      from,
      to,
      departDate,
      returnDate: tripType === 'roundTrip' ? returnDate : null,
      passengers,
      children,
      name,
      selectedType,
      BookingDate: bookingDate,
    };
    console.log('bookingData', bookingData)
    try {
      const response = await fetch(`${BaseUrl}/api/booking_flight`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bookingData),
      });

      if (response.ok) {
        const result = await response.json();
        toast.success(result.message);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while booking your flight.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-24">
      <h2 className="text-3xl font-bold text-center mb-6 text-blue-600">Book Your Flight</h2>

      <form className="space-y-6" onSubmit={handleSubmit}>
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
          <div className="flex items-center space-x-4">
            <input
              type="radio"
              id="roundTrip"
              name="tripType"
              value="roundTrip"
              checked={tripType === 'roundTrip'}
              onChange={() => setTripType('roundTrip')}
              className="form-radio text-blue-600"
            />
            <label htmlFor="roundTrip">Round Trip</label>
          </div>
          <div className="flex items-center space-x-4">
            <input
              type="radio"
              id="oneWay"
              name="tripType"
              value="oneWay"
              checked={tripType === 'oneWay'}
              onChange={() => setTripType('oneWay')}
              className="form-radio text-blue-600"
            />
            <label htmlFor="oneWay">One Way</label>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label htmlFor="from" className="mb-2 font-semibold">From</label>
            <div className="relative">
              <Plane className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                id="from"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                list="city-list"
                placeholder="City or Airport"
                className="w-full pl-10 pr-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <datalist id="city-list">
                {cities.map((city) => (
                  <option key={city} value={city} />
                ))}
              </datalist>
            </div>
          </div>
          <div className="flex flex-col">
            <label htmlFor="to" className="mb-2 font-semibold">To</label>
            <div className="relative">
              <Map className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                id="to"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                list="city-list"
                placeholder="City or Airport"
                className="w-full pl-10 pr-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label htmlFor="departDate" className="mb-2 font-semibold">Depart Date</label>
            <div className="relative">
              <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="date"
                id="departDate"
                value={departDate}
                onChange={(e) => setDepartDate(e.target.value)}
                className="w-full pl-10 pr-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
          {tripType === 'roundTrip' && (
            <div className="flex flex-col">
              <label htmlFor="returnDate" className="mb-2 font-semibold">Return Date</label>
              <div className="relative">
                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                <input
                  type="date"
                  id="returnDate"
                  value={returnDate}
                  onChange={(e) => setReturnDate(e.target.value)}
                  className="w-full pl-10 pr-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label htmlFor="passengers" className="mb-2 font-semibold">Passengers</label>
            <div className="relative">
              <Users className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <select
                id="passengers"
                value={passengers}
                onChange={(e) => setPassengers(parseInt(e.target.value))}
                className="w-full pl-10 pr-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none"
              >
                <option value="1">1 Adult</option>
                <option value="2">2 Adults</option>
                <option value="3">3 Adults</option>
                <option value="4">4 Adults</option>
              </select>
            </div>
          </div>

          {/* Dropdown for Children */}
          <div className="flex flex-col">
            <label htmlFor="children" className="mb-2 font-semibold">Children</label>
            <div className="relative">
              <Users className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <select
                id="children"
                value={children}
                onChange={(e) => setChildren(parseInt(e.target.value))}
                className="w-full pl-10 pr-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none"
              >
                <option value="0">No Children</option>
                <option value="1">1 Child</option>
                <option value="2">2 Children</option>
                <option value="3">3 Children</option>
                <option value="4">4 Children</option>
              </select>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200"
        >
          Book Flight
        </button>
      </form>
    </div>
  );
};

export default FlightBookingForm;
