import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BaseUrl } from '../BaseUrl';
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';

const ServiceDetails = () => {
    const { id } = useParams();
    const [service, setService] = useState(null);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        adults: 0,
        children: 0,
        number: ""
    });
    const [bookingLoading, setBookingLoading] = useState(false);

    useEffect(() => {
        const fetchServiceDetails = async () => {
            try {
                const response = await fetch(`${BaseUrl}/api/services/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch service details');
                }
                const data = await response.json();
                setService(data);
            } catch (error) {
                console.error('Error fetching service details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchServiceDetails();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setBookingLoading(true);


        const bookingData = {
            ...formData,
            title: service.title,
            duration: service.duration,
            date: service.date,
            price: service.price,
            paymentIntentId: '',
        };

        try {
            const response = await fetch(`${BaseUrl}/api/service-booking`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bookingData),
            });

            if (!response.ok) {
                throw new Error('Failed to book service');
            }

            const result = await response.json();
            toast.success(result.msg)
            setFormData({ name: '', email: '', adults: 0, children: 0, number: '' });
        } catch (error) {
            console.error('Error booking service:', error);
        } finally {
            setBookingLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!service) {
        return <div>Service not found.</div>;
    }

    return (
        <div className='pt-[100px]'>
            <div className="flex flex-col md:flex-row">
                <div className="min-h-screen p-8 md:w-2/3 pr-4">
                    <h1 className="text-3xl font-semibold mb-4">{service.name}</h1>
                    <img src={service.image} alt={service.name} className="w-full h-64 object-cover mb-6" />
                    <p
              dangerouslySetInnerHTML={{
                __html: service.description.replace(/\n/g, "<br/>"),
              }}
            />
                </div>
                <div className="md:w-1/3 flex items-center justify-center pt-[50px]">
                    <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-8">
                        <h2 className="text-2xl font-bold mb-6 text-center">Booking Form</h2>
                        <form className="space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label className="block text-gray-700 font-semibold mb-2">Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                                    placeholder="Enter your name"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-semibold mb-2">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                                    placeholder="Enter your Email"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-semibold mb-2">Number</label>
                                <input
                                    type="text"
                                    name="number"
                                    value={formData.number}
                                    onChange={handleChange}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                                    placeholder="Enter your Email"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-semibold mb-2">Children</label>
                                <input
                                    type="number"
                                    name="children"
                                    value={formData.children}
                                    onChange={handleChange}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                                    placeholder="Number of children"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-semibold mb-2">Adults</label>
                                <input
                                    type="number"
                                    name="adults"
                                    value={formData.adults}
                                    onChange={handleChange}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                                    placeholder="Number of adults"
                                    required
                                />
                            </div>
                            <button
                                disabled={bookingLoading}
                                type="submit"
                                className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition-all duration-300"
                            >
                                {bookingLoading ? (
                                    <div className="flex items-center justify-center">
                                        <FaSpinner className="animate-spin mr-2" />
                                        Processing...
                                    </div>
                                ) : (
                                    'Book now'
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceDetails;

