import React from 'react';
import { Star, Clock, Users, MapPin } from 'lucide-react';
import travel from '../../assets/kkraimg/travel-night.jpg'
const TourismCard = ({ image, price, title, duration, groupSize, location }) => (
    <div className="bg-white rounded-lg  overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105">
        <div className="relative">
            <img src={image} alt={title} className="w-full h-48 object-cover" />
            <div className="absolute top-4 left-4 bg-yellow-400 text-white px-2 py-1 rounded">
                ${price} per person
            </div>
        </div>
        <div className="p-4">
            <h3 className="text-xl font-semibold mb-2">{title}</h3>
            <div className="flex items-center text-gray-600 mb-2">
                <Clock size={16} className="mr-1" />
                <span className="text-sm">{duration}</span>
                <Users size={16} className="ml-4 mr-1" />
                <span className="text-sm">{groupSize}</span>
            </div>
            <div className="flex items-center text-gray-600">
                <MapPin size={16} className="mr-1" />
                <span className="text-sm">{location}</span>
            </div>
        </div>
        <div className="px-4 pb-4">
            <div className="flex items-center">
                <Star className="text-yellow-400" size={16} />
                <Star className="text-yellow-400" size={16} />
                <Star className="text-yellow-400" size={16} />
                <Star className="text-yellow-400" size={16} />
                <Star className="text-gray-300" size={16} />
                <span className="ml-2 text-sm text-gray-600">(4.0)</span>
            </div>
        </div>
    </div>
);

const TourismProgramCards = () => {
    const programs = [
        {
            image: travel,
            price: 299,
            title: "Sunset view of beautiful lakeside",
            duration: "3 Days",
            groupSize: "Up to 10",
            location: "Lake Como, Italy"
        },
        {
            image: travel,
            price: 399,
            title: "Mountain hiking adventure",
            duration: "5 Days",
            groupSize: "Up to 8",
            location: "Swiss Alps, Switzerland"
        },
        {
            image: travel,
            price: 199,
            title: "Historical city tour",
            duration: "2 Days",
            groupSize: "Up to 15",
            location: "Prague, Czech Republic"
        }
    ];

    return (
        <div className="container   py-8">
            <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-gray-800 mb-2">TOURISM PROGRAMS</h2>
                <p className="text-lg text-yellow-500 uppercase tracking-wide">Explore. Discover. Travel.</p>
                <div className="flex justify-center items-center mt-2">
                    <Star className="text-yellow-400" size={20} />
                    <Star className="text-yellow-400" size={20} />
                    <Star className="text-yellow-400" size={20} />
                    <Star className="text-yellow-400" size={20} />
                    <Star className="text-yellow-400" size={20} />
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {programs.map((program, index) => (
                    <TourismCard key={index} {...program} />
                ))}
            </div>
        </div>
    );
};

export default TourismProgramCards;