import React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { IoNewspaper } from "react-icons/io5";
import { LuContact2 } from "react-icons/lu";
import { FaArrowLeft, FaInfoCircle } from "react-icons/fa";

const BnbNavMob = ({ navbarOpen, setNavbarOpen }) => {

  return (
    <div className="lg:hidden block">
      <div className="bg-gray-800 h-[102vh] fixed top-0 left-20 right-0 p-2 m-0 ">
        <div className="flex justify-between items-center ">
          <Link
            className="inline-block py-2 mr-4  text-sm font-bold leading-relaxed text-white uppercase whitespace-nowrap"
            to="/"
          >
            <img
              className="object-cover w-28"
              src={require("assets/kkraimg/kkra_logo.png")}
              alt=""
            />
          </Link>
          <div className="flex justify-end px-4 pt-5 ">
            <FaArrowLeft
              className="text-white cursor-pointer"
              onClick={() => setNavbarOpen(!navbarOpen)}
            />
          </div>
        </div>
        <Grid item xs={12} sm={12}>
          <ul className="h-full text-white">
            <li className=" flex items-center px-2 py-2 hover:bg-sky-700 transition-all hover:bg-gradient-to-r hover:from-sky-600 hover:to-sky-800 hover:to-opacity-50 hover:pl-4">
              <Link to="" className="flex items-center gap-2 h-full">
                <FaInfoCircle />
                Home
              </Link>
            </li>
            <li className=" flex items-center px-2 py-2 hover:bg-sky-700 transition-all hover:bg-gradient-to-r hover:from-sky-600 hover:to-sky-800 hover:to-opacity-50 hover:pl-4">
              <Link to="/aboutus" className="flex items-center gap-2 h-full">
                <FaInfoCircle />
                About Us
              </Link>
            </li>
            <li className="flex items-center px-2 py-2 hover:bg-sky-700 transition-all hover:bg-gradient-to-r hover:from-sky-600 hover:to-sky-800 hover:to-opacity-50 hover:pl-4">
              <Link
                to="/subscription"
                className="flex items-center gap-2  h-full"
              >
                <IoNewspaper />
                Subscription
              </Link>
            </li>
            <li className="flex items-center px-2 py-2 hover:bg-sky-700 transition-all hover:bg-gradient-to-r hover:from-sky-600 hover:to-sky-800 hover:to-opacity-50 hover:pl-4">
              <Link to="/package" className="flex items-center gap-2  h-full">
                <IoNewspaper />
                Packages
              </Link>
            </li>
            <li className="flex items-center px-2 py-2 hover:bg-sky-700 transition-all hover:bg-gradient-to-r hover:from-sky-600 hover:to-sky-800 hover:to-opacity-50 hover:pl-4">
              <Link to="/contactus" className="flex items-center gap-2  h-full">
                <LuContact2 />
                Contact Us
              </Link>
            </li>
          </ul>
        </Grid>
      </div>
    </div>
  );
};

export default BnbNavMob;
