import React, { useState } from 'react';
import { Calendar, Users, Star, Building, Palmtree } from 'lucide-react';

const LuxuryAccommodationForm = () => {
  const [accommodationType, setAccommodationType] = useState('hotel');

  return (
    <div className="max-w-4xl mx-auto p-8  rounded-xl shadow-2xl mt-10">
      <h2 className="text-4xl font-extrabold text-center mb-8 text-blue-600">Experience Luxury in Dubai</h2>
      
      <form className="space-y-8">
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-8">
          <div className="flex items-center space-x-4">
            <input
              type="radio"
              id="hotel"
              name="accommodationType"
              value="hotel"
              checked={accommodationType === 'hotel'}
              onChange={() => setAccommodationType('hotel')}
              className="form-radio text-blue-600 focus:ring-blue-600"
            />
            <label htmlFor="hotel" className="flex items-center space-x-2">
              <Building size={20} />
              <span>Luxury Hotel</span>
            </label>
          </div>
          <div className="flex items-center space-x-4">
            <input
              type="radio"
              id="apartment"
              name="accommodationType"
              value="apartment"
              checked={accommodationType === 'apartment'}
              onChange={() => setAccommodationType('apartment')}
              className="form-radio text-blue-600 focus:ring-blue-600"
            />
            <label htmlFor="apartment" className="flex items-center space-x-2">
              <Palmtree size={20} />
              <span>Premium Apartment</span>
            </label>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label htmlFor="checkIn" className="mb-2 font-semibold">Check-in Date</label>
            <div className="relative">
              <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-600" size={20} />
              <input
                type="date"
                id="checkIn"
                className="w-full pl-10 pr-3 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label htmlFor="checkOut" className="mb-2 font-semibold ">Check-out Date</label>
            <div className="relative">
              <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-600" size={20} />
              <input
                type="date"
                id="checkOut"
                className="w-full pl-10 pr-3 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white"
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label htmlFor="guests" className="mb-2 font-semibold ">Number of Guests</label>
            <div className="relative">
              <Users className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-600" size={20} />
              <select
                id="guests"
                className="w-full pl-10 pr-3 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent bg-white appearance-none"
              >
                <option value="1">1 Guest</option>
                <option value="2">2 Guests</option>
                <option value="3">3 Guests</option>
                <option value="4">4 Guests</option>
                <option value="5+">5+ Guests</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col">
            <label htmlFor="rating" className="mb-2 font-semibold ">Minimum Star Rating</label>
            <div className="relative">
              <Star className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-600" size={20} />
              <select
                id="rating"
                className="w-full pl-10 pr-3 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white appearance-none"
              >
                <option value="5">5 Stars</option>
                <option value="4">4+ Stars</option>
                <option value="3">3+ Stars</option>
              </select>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="preferences" className="mb-2 font-semibold ">Special Preferences</label>
          <textarea
            id="preferences"
            rows="3"
            placeholder="Any specific requests or preferences?"
            className="w-full px-3 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white"
          ></textarea>
        </div>

        <button 
          type="submit" 
          className="w-full bg-blue-600 text-white py-4 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out text-lg font-semibold shadow-lg"
        >
          Find Your Luxurious Stay
        </button>
      </form>
    </div>
  );
};

export default LuxuryAccommodationForm;