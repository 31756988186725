import React, { useEffect, useState } from 'react';
import { FaFacebook, FaInstagram, FaTiktok, FaTwitter } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { BaseUrl } from '../../BaseUrl';

const InfluencerCard = ({ influencer }) => (
  <div className="bg-white rounded-xl shadow-lg overflow-hidden transition-transform transform hover:scale-105">
    <img
      src={influencer.image}
      alt={influencer.name}
      className="w-full h-56 object-cover"
    />
    <div className="p-6 text-center">
      <h3 className="text-2xl font-bold text-gray-800 mb-3">{influencer.name}</h3>
      <div className="flex justify-center space-x-6 mt-4">
        <a
          href={influencer.instagram}
          target="_blank"
          rel="noopener noreferrer"
          className="text-pink-500 hover:text-pink-600 transition-colors"
        >
          <FaInstagram size={28} />
        </a>
        <a
          href={influencer.twitter}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-400 hover:text-blue-500 transition-colors"
        >
          <FaTwitter size={28} />
        </a>
        <a
          href={influencer.tiktok}
          target="_blank"
          rel="noopener noreferrer"
          className="text-black hover:text-red-600 transition-colors"
        >
          <FaTiktok size={28} />
        </a>
        <a
          href={influencer.facebook}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-400 hover:text-blue-600 transition-colors"
        >
          <FaFacebook size={28} />
        </a>
      </div>
    </div>
  </div>
);


const Influencers = () => {
  const [influencers, setInfluencers] = useState([]);

  useEffect(() => {
    const fetchInfluencers = async () => {
      try {
        const response = await fetch(`${BaseUrl}/api/influencers`);
        if (!response.ok) throw new Error('Failed to fetch influencers');
        const data = await response.json();
        setInfluencers(data);
      } catch (err) {
        toast.error(err.message);
      }
    };
    fetchInfluencers();
  }, []);
  return (
    <div className=" min-h-auto px-4  sm:px-6 w-full lg:px-0  ">
      <div className="w-full">
        <h1 className="text-[#000000] text-3xl font-bold text-center pb-10">
          Influencers
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 w-full">
          {influencers.map((influencer) => (
            <InfluencerCard key={influencer.id} influencer={influencer} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Influencers;
