import React from 'react';
import ReactDOM from 'react-dom/client';
import 'assets/css/index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/css/App.css';
import 'assets/fonts/Poppins-Regular.ttf';
import 'react-multi-carousel/lib/styles.css';
import 'react-slideshow-image/dist/styles.css'
import ReactWhatsapp from 'react-whatsapp';
import reportWebVitals from './reportWebVitals';
import Root from 'routes';
import { ToastContainer } from 'react-toastify';
import { FaWhatsapp } from "react-icons/fa";


const root = ReactDOM.createRoot(document.getElementById('root'));

const pulseAnimation = `
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;
root.render(
  <>
    <style>{pulseAnimation}</style>
      <Root />
    <ToastContainer limit={1} />

    <ReactWhatsapp
      number="+971551085136"
      message="Hello, reaching out for help!"
      style={{
        color: '#fff',
        backgroundColor: '#25D366',
        borderRadius: '50%',
        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
        animation: 'pulse 1.5s infinite',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
      }}
    >
      <FaWhatsapp style={{ fontSize: '40px' }} />
    </ReactWhatsapp>


  </>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
