import React from "react";
import { Star } from "lucide-react";

const AboutUsSection = () => {
  return (
    <div className="w-full py-16 px-4 sm:px-6 lg:px-8 mt-10">
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-base text-yellow-500 font-semibold tracking-wide uppercase mb-2">
          ABOUT US
        </h2>
        <h1 className="text-2xl font-extrabold text-gray-900 sm:text-3xl sm:tracking-tight lg:text-4xl mb-4">
          KKRA Travels & Tours
        </h1>
        <div className="flex justify-center items-center mb-6">
          <Star className="text-yellow-400" size={24} />
          <Star className="text-yellow-400" size={24} />
          <Star className="text-yellow-400" size={24} />
          <Star className="text-yellow-400" size={24} />
          <Star className="text-yellow-400" size={24} />
        </div>
        <p className="mt-5 text-xl text-gray-500 leading-relaxed">
          Founded in 2015, KKRA has been at the forefront of creating
          unforgettable travel experiences in Dubai. With a passion for showcasing
          the diverse, captivating charmof Dubai, we specialize in tailored travel
          services that cater to a wide range of preferences, from adventurous
          desert escapades to luxurious city tours. Our mission is to provide our
          clients with immersive journeys that showcase the best of Dubai's iconic
          landscapes, rich culture, and modern marvels, all with a touch of
          authentic hospitality. Our team of dedicated travel professionals is
          committed to curating personalized itineraries that prioritize comfort,
          convenience, and local expertise. Whether you're visiting for abrief
          holiday, abusiness event, or a special occasion, KKRA promises a unique
          experience, transforming every journey into a remarkable memory
        </p>
        <div className="mt-10 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <a
              href="#"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
