import React, { useRef } from 'react';
import img1 from "assets/img/abtdesign.png";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Footerbn from 'pages/Footer/Footerbn';
import Container from "@mui/material/Container";
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com';

const ContactUs = () => {
  const formRef = useRef();

  const sendEmail = (values, actions) => {
    emailjs.sendForm('service_ljl7dzo', 'template_jy6n7qf', formRef.current, 'NO2pXno55HxArz-27')
      .then((result) => {
        console.log('SUCCESS!', result.text);
        toast.success("Message sent successfully");
        actions.resetForm();
      }, (error) => {
        console.log('FAILED...', error.text);
        toast.error("Failed to send message");
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      {/* <BnbNav /> */}
      <div className='flex flex-col items-center justify-center space-y-2 md:p-16 p-3 mt-24 '>
        <h1 className='md:text-4xl text-2xl font-medium text-center'>Let's Make Your Travel Dreams a Reality!</h1>
        <p className='md:text-xl text-lg font-normal text-[#4B4B4B] md:w-[526px] w-full text-center'>Your Journey Begins with a Click: Reach Out and Explore the World Together.</p>
      </div>

      <div className='flex flex-col items-center bg-[#F8F8F8] md:p-10 p-4 md:space-y-4 space-y-2 '>
        <div className='w-full flex md:flex-row flex-col md:gap-5 gap-3 items-center justify-center'></div>
        <div>
          <img src={img1} alt="" />
        </div>
      </div>
      <div className='flex flex-col items-center md:flex-row md:justify-center space-y-4 md:space-y-0 md:space-x-10 py-8 p-4'>
        <div className='flex xs:w-full md:w-fit items-center space-x-3 bg-gray-50 hover:bg-gray-100 hover:scale-105 p-4 rounded-lg shadow-md'>
          <div className="bg-[#2459BF] p-2 rounded-full text-white">
            <span role="img" aria-label="phone">📞</span>
          </div>
          <a href='tel:+971551085136'>
            <div>
              <h3 className='font-semibold text-lg'>Phone</h3>
              <p className='text-gray-600'>+971551085136</p>
            </div>
          </a>
        </div>
        <div className='flex xs:w-full md:w-fit items-center space-x-3 bg-gray-50 hover:bg-gray-100 hover:scale-105 p-4 rounded-lg shadow-md'>
          <div className="bg-[#2459BF] p-2 rounded-full text-white">
            <span role="img" aria-label="email">✉️</span>
          </div>
          <a href='mailto:info@kkratraveltours.com'><div>
            <h3 className='font-semibold text-lg'>Email</h3>
            <p className='text-gray-600'>info@kkratraveltours.com</p>
          </div>
          </a>
        </div>
        <div className='flex xs:w-full md:w-fit items-center space-x-3 bg-gray-50 hover:bg-gray-100 hover:scale-105 p-4 rounded-lg shadow-md'>
          <div className="bg-[#2459BF] p-2 rounded-full text-white">
            <span role="img" aria-label="address">📍</span>

          </div>
          <a
            href="https://maps.app.goo.gl/biHUFBUSNBK8FGQ4A"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="cursor-pointer">
              <h3 className="font-semibold text-lg">Address</h3>
              <p className="text-gray-600">Office 301, Building 5, Dubai Media City, Dubai, UAE.</p>
            </div>
          </a>
        </div>
      </div>

      <div className='px-3 pt-20 flex items-center justify-center xl:justify-start xl:pl-36 '>
        <span className='text-2xl font-extrabold'>Send Us A Message</span>
      </div>

      <Formik
        initialValues={{
          user_name: '',
          user_email: '',
          user_phone: '',
          user_city: '',
          message: ''
        }}
        validate={(values) => {
          const errors = {};
          if (!values.user_name) {
            errors.user_name = 'Required';
          }
          if (!values.user_email) {
            errors.user_email = 'Required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          sendEmail(values, actions);
        }}
      >
        {({ isSubmitting }) => (
          <Form ref={formRef} className='flex flex-col gap-4 items-center justify-center pt-6 px-3'>
            <div className='flex flex-col xl:flex-row gap-3 w-full md:w-[560px] xl:w-[1140px]'>
              <div className='flex flex-col'>
                <label htmlFor="user_name">Name*</label>
                <Field type="text" id="user_name" name="user_name" placeholder='Your Name' className='p-2 md:w-[560px] w-full h-[52px] border border-[#C1E1C2] rounded-lg' />
                <ErrorMessage name="user_name" component="div" className="text-red-500" />
              </div>
              <div className='flex flex-col '>
                <label htmlFor="user_email">Email*</label>
                <Field type="email" id="user_email" name="user_email" placeholder='Email Address' className='p-2 md:w-[560px] w-full h-[52px] border border-[#C1E1C2] rounded-lg' />
                <ErrorMessage name="user_email" component="div" className="text-red-500" />
              </div>
            </div>

            <div className='flex flex-col xl:flex-row gap-3 w-full md:w-[560px] xl:w-[1140px]'>
              <div className='flex flex-col w-full'>
                <label htmlFor="user_phone">Phone number</label>
                <Field type="tel" id="user_phone" name="user_phone" placeholder='+97' className='p-2 md:w-[560px] w-full h-[52px] border border-[#C1E1C2] rounded-lg' />
                <ErrorMessage name="user_phone" component="div" className="text-red-500" />
              </div>
              <div className='flex flex-col '>
                <label htmlFor="user_city">City</label>
                <Field type="text" id="user_city" name="user_city" placeholder='City' className='p-2 md:w-[560px] w-full h-[52px] border border-[#C1E1C2] rounded-lg' />
                <ErrorMessage name="user_city" component="div" className="text-red-500" />
              </div>
            </div>
            <div className='w-full flex justify-center items-center'>
              <div className='flex flex-col justify-start w-full md:w-[560px] xl:w-[1140px]'>
                <label htmlFor="message">Description</label>
                <Field as="textarea" id="message" name="message" placeholder="Enter description" rows="6" className="p-2 rounded-lg border border-[#C1E1C2]" />
                <ErrorMessage name="message" component="div" className="text-red-500" />
                <div className='pt-3 pb-2'>
                  <button type="submit" disabled={isSubmitting} className='md:py-3 py-2 bg-[#2459BF] rounded-full text-white md:w-[230px] w-[160px]'>
                    {isSubmitting ? 'Submitting...' : 'SEND MESSAGE'}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className='flex justify-center items-center'>
        <div className="flex justify-center w-full">
          <section className="flex relative z-50 md:top-20 w-[94%] lg:mb-10">
            <div className="">
            </div>
            <Container style={{ paddingLeft: "6px", paddingRight: "6px" }}>
              <div
                className="bg-[#AF3227]/95 md:h-[300px] h-full rounded-2xl lg:w-[90%] xl:w-[96%] mx-auto"
                style={{ backgroundImage: "url(" + require("assets/img/badgebg.png") + ")", backgroundRepeat: "no-repeat" }}
              >
                <Container>
                  <div className="items-center w-full justify-between md:h-[300px] h-[500px] md:flex">
                    <div className="lg:pl-10">
                      <div className="md:w-[445px] flex items-center justify-center w-full">
                        <p className="text-white text-[25px] text-center pt-3 md:pt-0 md:text-[36px] font-bold">
                          Get travel tips, offers and inspiration from BnByond
                        </p>
                      </div>
                      <div className="relative pt-4">
                        <input type="text" placeholder="Email address" className="md:w-[400px] lg:w-[495px] bg-transparent pl-5 border text-white placeholder-white border-white w-full h-[50px] rounded-2xl" />
                        <button className="text-[#C0392D] absolute right-0 text-base font-medium bg-white rounded-2xl md:w-[150px] w-full h-[50px]">Subscribe</button>
                      </div>
                    </div>
                    <div>
                      <img src={require("assets/img/downrrow.png")} />
                    </div>
                    <div className="relative">
                      <img src={require("assets/img/squad2.png")} />
                      <img src={require("assets/img/squad.png")} className="absolute z-40 top-4" />
                    </div>
                  </div>
                </Container>
              </div>
            </Container>
            <div className="">
            </div>
          </section>
        </div>
      </div>

      {/* <Footerbn /> */}
    </>
  );
}

export default ContactUs;
