import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import "react-input-range/lib/css/index.css";
import axios from "axios";
import { BaseUrl } from "BaseUrl";
import { use } from "react";
import { useNavigate } from "react-router-dom";

const LandingSearchBar = ({ searchTerm, setSearchTerm, onSearch }) => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/getalllocations`);
        setLocations(response.data.locations);
      } catch (err) {
        console.error("Error fetching locations:", err);
      }
    };
    fetchLocations();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim() !== "") {
      const filtered = locations.filter((location) =>
        location.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredLocations(filtered);
      setShowSuggestions(true);
    } else {
      setFilteredLocations([]);
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (location) => {
    navigate("/location-packages", { state: { location } });
  };
  return (
    <>
      <Container maxWidth="lg" className="min-h-[90vh]">
        <div className="flex items-center justify-center">
          <span className="text-white font-extrabold w-[90%] md:w-[80%] lg:w-[60%] xl:w-[54%] leading-tight lg:text-[60px] text-[45px]">
            Welcome to the world of Extraordinary Travel
          </span>
        </div>
        <div className="flex items-center justify-center">
          <span className="text-white font-medium w-[90%] pt-3 md:w-[80%] lg:w-[60%] xl:w-[54%] leading-tight text-[18px]">
            if you're in search of exciting adventure, unforgettable experience,
            and breathtaking places to explore, you've come to the right place!
          </span>
        </div>

        <div className="flex justify-center w-full h-auto">
          <div className="z-10 w-full flex flex-col items-center md:w-[400px] pt-20 h-auto">
            <div className="text-start pl-0 md:pl-0 md:text-start md:pt-0">
              <span className="text-[#FFFFFF] text-[24px] md:text-[30px] font-extrabold">
                Find Your Destination
              </span>
            </div>
            <div className="block md:flex md:flex-col lg:flex-row">
              <div className="w-full py-2 flex md:flex-row md:gap-2 md:justify-center lg:h-auto h-full bg-white shadow rounded-xl lg:rounded-l-xl lg:rounded-r-none min-h-[100px]">
                <div className="flex flex-col md:flex-row gap-2 lg:flex-row px-4 text-[#AFB5C1] w-full lg:w-auto items-center justify-between">
                  <div className="w-full lg:w-auto flex-grow">
                    <div className="flex items-center justify-between h-full ">
                      <div>
                        <input
                          type="text"
                          placeholder="Destination"
                          value={searchTerm}
                          onChange={handleInputChange}
                          className="outline-none px-4 py-3 border border-gray-300 rounded-xl"
                        />
                        {showSuggestions && filteredLocations.length > 0 && (
                          <ul className="absolute z-10 md:w-[400px] w-full bg-white border border-gray-300 rounded-xl mt-1 max-h-60 overflow-y-auto">
                            {filteredLocations.map((location) => (
                              <li
                                key={location.id}
                                className="px-4 py-2  hover:bg-gray-200 cursor-pointer"
                                onClick={() => handleSuggestionClick(location)}
                              >
                                {location.name}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      {/* <div className="pr-3">
                        <i className="fa-solid fa-location-dot"></i>
                      </div> */}
                    </div>
                  </div>

                  <div className="flex w-full justify-center h-[55%]">
                    <button
                      onClick={onSearch}
                      className="text-sm md:w-[100px] w-full font-bold text-white transition-all duration-150 ease-linear rounded-full shadow bg-[#2459BF] hover:shadow-lg flex items-center justify-center md:h-full h-[50px] md:py-1 py-0"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex items-center rounded-xl lg:rounded-tl-none md:mt-3  lg:rounded-bl-none justify-center mt-3 lg:mt-0 w-full lg:w-[120px] lg:h-auto h-auto bg-[#E8AD21]">
                <span className="lg:h-auto text-center text-[#000000] font-[500] md:text-[18px] text-base py-2 lg:py-0">
                  Where can you travel right now?
                </span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default LandingSearchBar;
