import React, { useState } from 'react';
import { Car, Plane, Calendar, Clock, Users, MapPin } from 'lucide-react';

const TransportServicesForm = () => {
  const [serviceType, setServiceType] = useState('airportTransfer');

  return (
    <div className="max-w-4xl mx-auto p-8  rounded-xl shadow-2xl mt-24">
      <h2 className="text-4xl font-extrabold text-center mb-8 text-blue-600">Dubai Transport Services</h2>
      
      <form className="space-y-8">
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-8">
          <div className="flex items-center space-x-4">
            <input
              type="radio"
              id="airportTransfer"
              name="serviceType"
              value="airportTransfer"
              checked={serviceType === 'airportTransfer'}
              onChange={() => setServiceType('airportTransfer')}
              className="form-radio text-blue-600 focus:ring-blue-600"
            />
            <label htmlFor="airportTransfer" className="flex items-center space-x-2 text-blue-600">
              <Plane size={20} />
              <span>Airport Transfer</span>
            </label>
          </div>
          <div className="flex items-center space-x-4">
            <input
              type="radio"
              id="carRental"
              name="serviceType"
              value="carRental"
              checked={serviceType === 'carRental'}
              onChange={() => setServiceType('carRental')}
              className="form-radio text-blue-600 focus:ring-blue-600"
            />
            <label htmlFor="carRental" className="flex items-center space-x-2 text-blue-600">
              <Car size={20} />
              <span>Car Rental</span>
            </label>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label htmlFor="pickupDate" className="mb-2 font-semibold ">Pickup Date</label>
            <div className="relative">
              <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-600" size={20} />
              <input
                type="date"
                id="pickupDate"
                className="w-full pl-10 pr-3 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label htmlFor="pickupTime" className="mb-2 font-semibold ">Pickup Time</label>
            <div className="relative">
              <Clock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-600" size={20} />
              <input
                type="time"
                id="pickupTime"
                className="w-full pl-10 pr-3 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white"
              />
            </div>
          </div>
        </div>

        {serviceType === 'airportTransfer' && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col">
              <label htmlFor="flightNumber" className="mb-2 font-semibold ">Flight Number</label>
              <div className="relative">
                <Plane className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-600" size={20} />
                <input
                  type="text"
                  id="flightNumber"
                  placeholder="Enter flight number"
                  className="w-full pl-10 pr-3 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label htmlFor="transferType" className="mb-2 font-semibold ">Transfer Type</label>
              <div className="relative">
                <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-600" size={20} />
                <select
                  id="transferType"
                  className="w-full pl-10 pr-3 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white appearance-none"
                >
                  <option value="airportToHotel">Airport to Hotel</option>
                  <option value="hotelToAirport">Hotel to Airport</option>
                  <option value="roundTrip">Round Trip</option>
                </select>
              </div>
            </div>
          </div>
        )}

        {serviceType === 'carRental' && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col">
              <label htmlFor="returnDate" className="mb-2 font-semibold ">Return Date</label>
              <div className="relative">
                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-600" size={20} />
                <input
                  type="date"
                  id="returnDate"
                  className="w-full pl-10 pr-3 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label htmlFor="carType" className="mb-2 font-semibold ">Car Type</label>
              <div className="relative">
                <Car className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-600" size={20} />
                <select
                  id="carType"
                  className="w-full pl-10 pr-3 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white appearance-none"
                >
                  <option value="economy">Economy</option>
                  <option value="midsize">Midsize</option>
                  <option value="luxury">Luxury</option>
                  <option value="suv">SUV</option>
                </select>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col">
          <label htmlFor="passengers" className="mb-2 font-semibold">Number of Passengers</label>
          <div className="relative">
            <Users className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-600" size={20} />
            <select
              id="passengers"
              className="w-full pl-10 pr-3 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent bg-white appearance-none"
            >
              <option value="1">1 Passenger</option>
              <option value="2">2 Passengers</option>
              <option value="3">3 Passengers</option>
              <option value="4">4 Passengers</option>
              <option value="5+">5+ Passengers</option>
            </select>
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="specialRequests" className="mb-2 font-semibold ">Special Requests</label>
          <textarea
            id="specialRequests"
            rows="3"
            placeholder="Any specific requests or preferences?"
            className="w-full px-3 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent bg-white"
          ></textarea>
        </div>

        <button 
          type="submit" 
          className="w-full bg-blue-600 text-white py-4 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out text-lg font-semibold shadow-lg"
        >
          Book Your Transport
        </button>
      </form>
    </div>
  );
};

export default TransportServicesForm;