import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { BaseUrl } from "../BaseUrl";
import Slider from "react-slick";

const stripePromise = loadStripe(process.env.REACT_APP_PK_KEY);

const Payment = () => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const navigate = useNavigate();

  const { bookingData } = location.state || {};
  const { bookingId, price, title, image, description } = bookingData;
  console.log("bookingData", bookingData);
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (price) {
      fetch(`${BaseUrl}/api/create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ bookingId, price }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data && data.clientSecret) {
            setClientSecret(data.clientSecret);
          } else {
            setErrorMessage("Error: clientSecret is missing in the response");
          }
        })
        .catch(() => {
          setErrorMessage("Error fetching client secret.");
        });
    }
  }, [bookingId, price]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) return;
    if (!clientSecret) {
      console.error("Client secret is missing.");
      setErrorMessage("Client secret is missing.");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {},
        },
      }
    );

    if (error) {
      setErrorMessage(error.message);
      console.error("Payment failed:", error.message);
      setLoading(false);
    } else if (paymentIntent.status === "requires_capture") {
      try {
        bookingData.paymentIntentId = paymentIntent.id;

        const bookingResponse = await axios.post(
          `${BaseUrl}/api/bookings`,
          bookingData
        );

        if (bookingResponse.status === 201) {
          toast.success("Payment and booking successful!");
          // navigate('/booking-success');
        } else {
          setErrorMessage(
            "Booking was not successful, please contact support."
          );
        }
      } catch (bookingError) {
        setErrorMessage(
          "Payment was successful but there was an error processing the booking."
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="max-w-6xl mx-auto p-6 pt-[100px]">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white shadow-lg rounded-lg p-6">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-bold mb-4">{title}</h2>
            <h2 className="text-2xl font-bold mb-4">AED {price}</h2>
          </div>
          {/* <img
            src={image}
            alt={title}
            className="w-full h-64 object-cover rounded-lg mb-4"
          /> */}
          <div className="">
            {image && image.length > 0 ? (
              <Slider {...settings} className="w-full">
                {Array.isArray(image) ? (
                  image.map((img, index) => (
                    <div key={index}>
                      <img
                        src={img}
                        alt={`Slide ${index + 1}`}
                        className="w-full md:h-[400px] rounded-md h-full object-cover"
                      />
                    </div>
                  ))
                ) : (
                  <div>
                    <img
                      src={image[0]}
                      alt="Default Slide"
                      className="w-full h-48 object-cover"
                    />
                  </div>
                )}
              </Slider>
            ) : (
              <p className="text-gray-500">No images available.</p>
            )}
          </div>
          <p className="text-gray-600">{description}</p>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6">
          {errorMessage && (
            <div className="text-red-500 mb-4">{errorMessage}</div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <CardElement className="p-4 border rounded-lg" />
            </div>
            <button
              type="submit"
              className={`w-full bg-blue-600 text-white py-2 px-4 rounded-lg ${
                loading ? "opacity-50" : ""
              }`}
              disabled={!stripe || loading}
            >
              {loading ? "Processing..." : `Pay ${price} AED`}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default function StripeContainer() {
  return (
    <Elements stripe={stripePromise}>
      <Payment />
    </Elements>
  );
}
