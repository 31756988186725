import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BaseUrl } from "BaseUrl";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

// Function to render stars based on rating
const renderRating = (rating) => {
  return (
    <div className="flex justify-center items-center">
      {Array.from({ length: 5 }, (_, index) => (
        <span
          key={index}
          className={`text-[24px] ${
            index < rating ? "text-yellow-500" : "text-gray-300"
          }`}
        >
          ★
        </span>
      ))}
      <span className="text-sm text-gray-700 pl-2">{rating}/5</span>
    </div>
  );
};

const ReviewSection = () => {
  const [reviews, setReviews] = useState([]);

  // Fetch reviews from API
  const fetchReviews = async () => {
    try {
      const response = await fetch(`${BaseUrl}/api/reviews`);
      const data = await response.json();
      setReviews(data);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []); // Add an empty dependency array to fetch only on component mount

  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          What Our Clients Say
        </h2>
        <p className="mt-4 text-lg text-gray-600">
          Hear from our satisfied customers
        </p>
      </div>

      <div className="mt-10 max-w-6xl mx-auto">
        <Slider {...settings}>
          {reviews.map((testimonial, index) => (
            <div key={index}>
              <div className="p-6 text-center mx-4">
                <img
                  className="w-22 h-22 border-2 border-[#17C3B3] shadow-lg rounded-full mx-auto mb-4"
                  src={
                    "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp"
                  }
                />
                <p className="mt-4 text-gray-600 pt-6">
                  ”{testimonial.description}”
                </p>
                <div className="pt-10">
                  {renderRating(testimonial.review_start)}
                </div>
                <h3 className="text-lg font-semibold pt-5 text-gray-900">
                  {testimonial.name}
                </h3>
                <p className="text-sm text-gray-500">{testimonial.company}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ReviewSection;
