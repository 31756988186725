import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import HomeCard from "./Cards/HomeCard";
import { BaseUrl } from "../BaseUrl";

const LocationPackages = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { location } = state || {};
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [filteredPackages, setFilteredPackages] = useState([]);

  const handleSelectPackage = (pkg) => {
    setSelectedPackage(pkg);
    navigate("/tour-detail", { state: { selectedPackage: pkg } });
  };
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await fetch(`${BaseUrl}/api/getpackages`);
        if (!response.ok) {
          throw new Error("Failed to fetch packages");
        }
        const data = await response.json();
        setPackages(data.packages);

        // Filter packages based on location
        const relatedPackages = data.packages.filter(
          (pkg) => pkg.locationId.name === location.name
        );
        setFilteredPackages(relatedPackages);
      } catch (err) {
        console.error(err);
      }
    };

    fetchPackages();
  }, [location]);

  if (!location) return <div>No location selected</div>;

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-[100px] py-4">
      <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center sm:text-left">
        Packages for {location.name}
      </h2>
      {filteredPackages.length === 0 ? (
        <p className="text-center text-gray-500 text-lg">
          No packages available for this location.
        </p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {filteredPackages.map((pkg, index) => (
            <div
              key={index}
              onClick={() => handleSelectPackage(pkg)}
              className="w-full rounded-lg  bg-white shadow-sm hover:shadow-md transition-shadow duration-300"
            >
              <HomeCard
                title={pkg.title}
                rating={pkg.rating}
                days={pkg.days}
                nights={pkg.nights}
                hours={pkg.hours}
                price={pkg.price}
                image={pkg.image}
                name={pkg.locationId.name}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LocationPackages;
